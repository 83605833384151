import { types } from 'mobx-state-tree';

const IssuesManagementUtilities = types
  .model('IssuesManagementUtilities', {
    showAddIssuesModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['project']),
    fixed: types.optional(types.integer, 0),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.integer), []),
    isDownloading: types.optional(types.boolean, false),
    showViewIssuesModal: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateIssuesModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateIssuesModal = !self.showAddOrUpdateIssuesModal;
    },

    setToggleShowViewIssuesModal() {
      self.showViewIssuesModal = !self.showViewIssuesModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = selectedRowsKeys;
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setFixed(fixed) {
      self.fixed = fixed;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },

    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default IssuesManagementUtilities;
