export const UserSettingsSVG = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="user-friends"
    className="svg-inline--fa fa-user-friends fa-w-20"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    width="1em"
    height="1em"
  >
    <path
      fill="currentColor"
      d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
    ></path>
  </svg>
)

export const UserSVG = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="user"
    className="svg-inline--fa fa-user fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="1em"
    height="1em"
  >
    <path
      fill="currentColor"
      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
    ></path>
  </svg>
)

export const LanguageSVG = () => (
  <svg
    id="Help_Icon"
    data-name="Help Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1.2em"
    height="1.2em"
    viewBox="0 0 23 23"
  >
    <rect id="Path" width="23" height="23" fill="rgba(0,0,0,0)" />
    <path
      id="Icon"
      d="M8.5,19.583a.934.934,0,0,1-.666-.278L5.667,17.125H1.889A1.9,1.9,0,0,1,0,15.223V1.9A1.9,1.9,0,0,1,1.889,0H15.111A1.9,1.9,0,0,1,17,1.9v13.32a1.9,1.9,0,0,1-1.889,1.9H11.334L9.161,19.3A.932.932,0,0,1,8.5,19.583Zm-.94-6.263v1.9H9.444v-1.9ZM8.5,3.805A1.908,1.908,0,0,1,9.841,7.05L8.67,8.248a3.816,3.816,0,0,0-1.076,2.226.83.83,0,0,0,.194.654.806.806,0,0,0,.608.278h.292a.817.817,0,0,0,.8-.723,3.146,3.146,0,0,1,1.057-1.96l.85-.875a3.056,3.056,0,0,0,.879-2.141A3.8,3.8,0,0,0,8.5,1.9a3.726,3.726,0,0,0-2.284.773A3.843,3.843,0,0,0,4.874,4.633a.847.847,0,0,0,.134.745.793.793,0,0,0,.641.321h.283a.849.849,0,0,0,.784-.609A1.893,1.893,0,0,1,8.5,3.805Z"
      transform="translate(3 2)"
      fill="currentColor"
    />
  </svg>
)
