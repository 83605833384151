import { types } from 'mobx-state-tree';
import {  IssuesManagementUtilities, IssuesState } from '@app_modules/Issues/model';


const AppStore = {

  issues: types.optional(IssuesState, {}),
IssuesManagementUtilities: types.optional(IssuesManagementUtilities, {}),


};

export default AppStore;
