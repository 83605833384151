import { flow, cast } from 'mobx-state-tree';
import axios from 'axios';
import * as Cryptr from 'cryptr';
const cryptr = new Cryptr(process.env.REACT_APP_CRYPTR_SECRET_KEY);

const CSRF_TOKEN_URL = '/api/csrf/get_csrf_token';

const ApplyRootCrud = (apiPath, self) => {
  return {
    CREATE: flow(function* (values) {
      try {
        self.isCreating = true;
        const { data } = yield axios.post(apiPath, values);
        data.key = data.id;
        self.state.push(data);
        self.isCreating = false;

        return [{ message: 'Global_#Created!', data }, null];
      } catch (error) {
        self.isCreating = false;
        return [null, error];
      }
    }),

    LIST: flow(function* (params, shouldReturnList = false) {
      try {
        //APPLY PAGINATION
        self.loading = true;

        const {
          data: { data, total_items }
        } = yield axios.get(apiPath, { params });

        const datWithKey = data.map((d) => ({ ...d, key: d.id }));

        self.state = cast(datWithKey);
        self.total = total_items;
        self.loading = false;

        if (shouldReturnList) {
          return [datWithKey, null];
        }
      } catch (error) {
        return [null, error];
      }
    }),

    UPDATE: flow(function* (id, values) {
      try {
        const { data } = yield axios.put(`${apiPath}/${id}`, values);
        self.state.splice(
          self.state.findIndex((data) => data.id === id),
          1,
          data
        );

        return [{ message: 'Global_#Updated!' }, null];
      } catch (error) {
        return [null, error];
      }
    }),

    RETRIEVE: flow(function* (id) {
      try {
        const { data } = yield axios.get(`${apiPath}/${id}`);
        Object.assign(self.single, data);
        return data;
      } catch (error) {
        return error;
      }
    }),

    DELETE: flow(function* (id) {
      try {
        id = Array.isArray(id) ? id : [id];

        const { data: result } = yield axios.delete(apiPath, {
          data: { ids: id }
        });

        if (result) {
          if (Array.isArray(id)) {
            self.state = self.state.filter((c) => !id.includes(c.id));
          } else {
            self.state.splice(
              self.state.findIndex((data) => data.id === id),
              1
            );
          }
        }
      } catch (error) {
        console.log(error, 'error');
      }
    }),

    GET_CSRF_TOKEN: flow(function* () {
      const {
        data: { csrfToken }
      } = yield axios.get(CSRF_TOKEN_URL);
      const decryptedString = csrfToken; //cryptr.decrypt(csrfToken);
      axios.defaults.headers.post['X-CSRF-Token'] = decryptedString;
      axios.defaults.headers.delete['X-CSRF-Token'] = decryptedString;
      axios.defaults.headers.put['X-CSRF-Token'] = decryptedString;
    })
  };
};

export default ApplyRootCrud;
