import { lazy } from 'react';

const IssuesHeader = lazy(() => import('./IssuesHeader'));
const IssuesTable = lazy(() => import('./IssuesTable'));
const AddOrUpdateIssuesModal = lazy(() => import('./AddOrUpdateIssuesModal'));
const ViewIssuesModal = lazy(() => import('./ViewIssuesModal'));
const DetailsForm = lazy(() => import('./DetailsForm'));
const ProjectForm = lazy(() => import('./ProjectForm'));
const RiskAndStatusForm = lazy(() => import('./RiskAndStatusForm'));
const StatusTag = lazy(() => import('./StatusTag'));
const StatusCircle = lazy(() => import('./StatusCircle'));

export {
  IssuesHeader,
  IssuesTable,
  AddOrUpdateIssuesModal,
  DetailsForm,
  ProjectForm,
  RiskAndStatusForm,
  ViewIssuesModal,
  StatusTag,
  StatusCircle
};
