import { types, cast } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';
import UsersModel from '@core_modules/UserManagement/model/UsersModel';

const IssuesModel = types
  .model('IssuesModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    project: types.optional(types.string, ''),
    category: types.optional(types.string, ''),
    date_found: types.optional(types.string, ''),
    desc_issue: types.optional(types.string, ''),
    date_fixed: types.optional(types.maybeNull(types.string), ''),
    desc_fixed: types.optional(types.maybeNull(types.string), ''),
    probability: types.optional(types.maybeNull(types.string), ''),
    impact: types.optional(types.maybeNull(types.string), ''),
    risk: types.optional(types.maybeNull(types.string), ''),
    preventive_action: types.optional(types.maybeNull(types.string), ''),
    fixed: types.optional(types.boolean, false),
    reported_by: types.optional(types.maybeNull(types.string), ''),
    category_list: types.optional(types.array(Dropdownmodel), []),
    UserReportedBy: types.optional(types.maybeNull(UsersModel, {}))
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList(type, list) {
      self[`${type}_list`] = cast(list);
    },

    setCurrentSelectedDropdown(currentSelected) {
      self.currentSelectedDropdown = currentSelected;
    }
  }));

export default IssuesModel;
